<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Junio 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">

        <!-- filtros de busqueda y boton para agregar un relación -->
        <div class="d-flex pt-3">
            <v-text-field class="me-2" outlined type="number" dense hide-details label="Código de bodega"
                v-model="buscadorCodigoBodega" @keypress="validarLetra($event)">
            </v-text-field>

            <v-text-field class="filtros me-2" outlined type="number" dense hide-details label="Código de producto"
                v-model="buscadorCodigoProducto" @keypress="validarLetra($event)">
            </v-text-field>

            <v-text-field class="filtros me-2" outlined dense hide-details label="Nombre del producto"
                v-model="buscadorNombreProducto">
            </v-text-field>

            <v-text-field class="filtros me-2" outlined dense hide-details label="Nombre de la bodega"
                v-model="buscadorNombreBodega">
            </v-text-field>

            <v-autocomplete ref="filtroEstado" v-model="buscarEstado" label="Estado" outlined dense hide-details :items="tiposEstado" 
                :menu-props="{offsetY: true, maxHeight: 200}" @keydown="tabulador($event, 'buscarEstado', 'filtroEstado')"
                no-data-text="Sin resultados">
            </v-autocomplete>

            <v-tooltip left color="success">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on"
                        @mousedown.prevent="dialog = true">
                        <v-icon> add </v-icon>
                    </v-btn>
                </template>
                <span>Agregar</span>
            </v-tooltip>

            <v-tooltip left color="blue">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="ms-2" fab color="blue" v-bind="attrs" v-on="on"
                        @mousedown.prevent="componenteEstado = true">
                        <v-icon color="white">autorenew</v-icon>
                    </v-btn>
                </template>
                <span>Cambiar estado</span>
            </v-tooltip>
            
            <v-tooltip left :color="!descargarActivo ? 'grey' : 'primary'">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="ms-2" fab v-bind="attrs" v-on="on" 
                        :color="!descargarActivo ? 'grey' : 'primary'" 
                        :class="!descargarActivo ? 'deshabilitar': ''"
                        @mousedown.prevent="!descargarActivo ? null : descargarResultadoDeBusqueda()">
                        <v-icon color="white">download</v-icon>
                    </v-btn>
                </template>
                <span>Descargar CSV</span>
            </v-tooltip>
        </div>

        <!-- dialogo para crear una relacion -->
        <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="49.7rem">
            <v-card>
                <v-tabs v-model="opciones" background-color="blue darken-4" dark class="v-tabs--fixed-tabs">
                    <v-tabs-slider color="yellow" class="v-tabs"></v-tabs-slider>
                    <v-tab :disabled="leyendoArchivo" style="margin-right: 40px; margin-left: 17px !important;">Agregar relaciones uno a uno</v-tab>
                    <v-tab>Agregar relaciones masivas</v-tab>
                </v-tabs>

                <!-- formulario crear una relacion -->
                <v-tabs-items v-model="opciones">
                    <v-tab-item>
                        <v-card-text class="pa-5">
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="nuevaRelacion">

                                    <!-- Componente para seleccionar una bodega a relacionar -->
                                    <div style="padding: 0 0rem">
                                        <RelateProductSelect ref="relateProductSelect"
                                            :parametrosDeBusqueda="{ codBode: '', nomBode: '' }"
                                            :codBodeSeleccionado="codBodeSeleccionado"
                                            @bodega-seleccionada="manejoDeLaBodega"
                                            @campo-vacio="campoVacio">
                                        </RelateProductSelect>
                                    </div>

                                    <!-- Componente para seleccionar un producto a relacionar -->
                                    <div style="padding: 0 0rem">
                                        <RelateProductTable ref="relateProductTable"
                                            :usoDeHeaders="['CÓDIGO DEL PRODUCTO', 'NOMBRE DEL PRODUCTO']"
                                            :usoDeFiltros="['filtro1', 'filtro2']" 
                                            :itemSeleccionado="itemSeleccionado"
                                            @busqueda-cambiada="manejoBusquedaCambiada"
                                            @item-productoSeleccionado="manejoDelItemSeleccionado"
                                            @mostrar-mensaje-error="desactivarBotonGuardar"
                                            @ocultar-mensaje-error="activarBotonGuardar"
                                            @mostrar-respuesta-error="noGuardar"
                                            @ocultar-respuesta-error="siGuardar"
                                            :datosExistentesError="datosExistentesError"
                                            :datosErrorRelacion="datosErrorRelacion"
                                            :campoBodegaVacio="campoBodegaVacio">
                                        </RelateProductTable>
                                    </div>

                                    <!-- Botones de cerrar, guardar y actualizar que se activan cuando se abre el dialogo para relación-->
                                    <div class="d-flex justify-end">
                                        <v-btn color="error" text @click="reset()">
                                            CERRAR
                                        </v-btn>
                                        <v-btn depressed :disabled="!puedeGuardar || inProgress === true || invalid" color="success" text
                                            @click="guardarRelacion()">
                                            GUARDAR
                                        </v-btn>
                                    </div>
                                </v-form>
                            </validation-observer>
                        </v-card-text>
                    </v-tab-item>

                    <v-tab-item>
                        <v-card-text class="pa-4">
                            <validation-observer v-slot="{ invalid }">
                                <v-form ref="archivoSubido">

                                    <div class="archivo ma-1" v-show="verCSV">
                                        <input :disabled="leyendoArchivo" type="file" class="seleccionarArchivo" accept=".csv"
                                            @change="lectorCSV($event)" ref="cargaDeRelaciones">
                                        <p v-if="validandoDocumento" style="padding-top: 0.6rem;">
                                            Validando la información del archivo, espere un momento...
                                            <v-icon :class="{ 'rotate-animation': validandoDocumento }"
                                                large>rotate_right
                                            </v-icon>
                                        </p>
                                        <p v-else-if="formatoIncorrecto" style="color: red; margin-top: 1.4rem;">
                                            <v-icon class="mr-2" style="color: red;">error</v-icon>
                                            {{ mensajeIncorrecto }}
                                        </p>
                                        <p v-else>Arrastre un archivo .CSV con las relaciones de productos con bodegas a ingresar,
                                            <br>
                                            También puede hacer click en este cuadro.
                                        </p>
                                    </div>
                                    <v-alert v-if="tabla" class="pa-2 ma-0" color="primary" transition="scale-transition" outlined>
                                        <h4 class="d-flex justify-center"><v-icon color="primary" class="mr-2">info</v-icon>{{ mensajeCantidad }}</h4>
                                    </v-alert>
                                    <v-simple-table class="tablaRelaciones mt-1" fixed-header height="370px" v-show="tabla"
                                        ref="tablaRelacionesMasivas" id="miTabla">
                                        <template v-slot:default>
                                            <thead class="header">
                                                <tr>
                                                    <th> PRODUCTO </th>
                                                    <th> BODEGA </th>
                                                    <th> VALIDACIÓN </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(registro, registroIndex) in registrosFiltrados"
                                                    :key="registroIndex">
                                                    <td>{{ registro.producto }}</td>
                                                    <td>{{ registro.bodega }}</td>
                                                    <td>
                                                        <div
                                                            v-if="validacionesFiltradas[registroIndex] && validacionesFiltradas[registroIndex].length > 0">
                                                            <div v-for="(mensaje, subIndex) in validacionesFiltradas[registroIndex]"
                                                                :key="subIndex" class="error-message">
                                                                {{ mensaje }}
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <v-icon color="green">check_circle</v-icon>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>

                                    <!-- Botones de cerrar, guardar y actualizar que se activan cuando se abre el dialogo para relación-->
                                    <div class="d-flex justify-end mt-2">
                                        <v-btn color="error" :disabled="leyendoArchivo" text @click="reset()">
                                            CERRAR
                                        </v-btn>
                                        <v-btn v-if="mostrarBotones" color="blue" text
                                            @click="volverACargarDocumento">VOLVER</v-btn>
                                        <v-btn v-if="mostrarBotones && !hayErrores" depressed :disabled="hayErrores || cantidadRegistros === 0 || invalid"
                                            color="success" text @click="guardarMultiplesRelaciones()">
                                            GUARDAR
                                        </v-btn>
                                        <v-btn v-if="mostrarBotones && hayErrores && cantidadRegistros > 0" 
                                            color="success" text @click="descargarResultadoDeValidaciones()">
                                            DESCARGAR
                                        </v-btn>
                                    </div>
                                    <div v-if="inProgress === true" class="overlay">
                                        <v-alert class="notificationValidation" persistent transition="dialog-bottom-transition">
                                            <v-icon class="d-flex justify-center rotate-animation" color="white" large>rotate_right</v-icon>
                                            <span class="d-flex justify-center">Guardando</span>
                                        </v-alert>
                                    </div>
                                </v-form>
                            </validation-observer>
                        </v-card-text>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>

        <!-- Tabla que muestra los registros del relación -->
        <v-data-table :loading="loading" fixed-header :headers="headersRelaciones" :items="listaDeRelaciones"
            :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas"
            class="elevation mt-4" height="66vh" :footer-props="footerPropsTabla">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="(item) in items" :key="item.index">
                        <td class="text-center">
                            {{ item.COD_BODE }}
                        </td>
                        <td class="text-center">
                            {{ item.COD_PROD }}
                        </td>
                        <td>
                            {{ item.NOM_PROD }}
                        </td>
                        <td>
                            {{ item.NOM_BODE }}
                        </td>
                        <td>
                            <span v-if="item.ACT_ESTA == 'A'"><v-icon color="success"> check_circle </v-icon> Activo</span>
                            <span v-if="item.ACT_ESTA == 'B'"><v-icon color="error"> cancel </v-icon> Inactivo</span>
                        </td>
                        <td class="text-center">
                            <v-tooltip left :color="item.ACT_ESTA === 'A' ? 'error' : 'success'">
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="abrirDialogoEstado(item)">
                                        <v-icon v-if="item.ACT_ESTA === 'A'" color="error"> person_add_disabled </v-icon>
                                        <v-icon v-if="item.ACT_ESTA === 'B'" color="success"> how_to_reg </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.ACT_ESTA === 'A' ? 'Inactivar' : 'Activar' }}</span>
                            </v-tooltip>
                            <v-tooltip left color="#0d47a1" v-if="item.OBS_PROD !== null && item.OBS_PROD !== ''">
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn icon v-bind="attrs" v-on="on" @click="abrirDialogoObservacion(item)">
                                        <v-icon color="#0d47a1"> chat </v-icon>
                                    </v-btn>
                                </template>
                                <span>Observación</span>
                            </v-tooltip>
                            <v-icon v-if="item.OBS_PROD === null || item.OBS_PROD === ''" color="#5cb7ff"> speaker_notes_off </v-icon>
                        </td>
                    </tr>
                    <tr v-if="calcularNoHayEscritura() && !noHayResultadosBusqueda">
                        <td :colspan="headersRelaciones.length + 1" class="mensaje">
                            <span><v-icon class="icono"> feedback </v-icon></span>
                            <div>
                                <em>No hay registros, utilice los filtros para buscar un producto relacionado con una bodega.</em>
                            </div>
                        </td>
                    </tr>
                    <tr v-else-if="noHayResultadosBusqueda">
                        <td :colspan="headersRelaciones.length + 1" class="mensaje">
                            <span><v-icon color="#ff5252" class="icono">search_off</v-icon></span>
                            <div>
                                <em style="color: #ff5252;">No se encontraron resultados para la búsqueda, ingrese un nuevo valor válido.</em>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- Dialogo para cambiar el estado uno a uno -->
        <v-dialog v-model="dialogoCambiarEstado" persistent transition="dialog-bottom-transition" max-width="22.7rem">
            <v-card>
                <v-card-title class="fondoDialog pt-2 pb-2">
                    <span class="text-h6">{{ mensajeEstado }}</span>
                </v-card-title>
                <!-- <selector-producto filtros="codigo" @resultado=""></selector-producto> -->
                <v-card-text>
                    <div class="d-flex align-items-center mt-3">
                        <v-textarea id="observacionId" v-model="observacionEstado" label="Observación" hide-details
                            rows="2" no-resize dense outlined :maxlength="maximoCaracteres" :readonly="inProgress === true">
                        </v-textarea>
                    </div>
                    <div class="d-flex justify-end mr-4" style="font-size: smaller;">
                        <span>{{ contadorCaracteres }} / {{ maximoCaracteres }}</span>
                    </div>
                    <div class="d-flex justify-end pt-3">
                        <v-btn text color="error" @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn depressed color="success" @click="cambiarEstado()" :disabled="inProgress === true">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Dialogo para cambiar de masivamente los estados de varias relaciones producto bodega por medio del componente EstadoProductoBodega-->
        <v-dialog v-model="componenteEstado" persistent transition="dialog-bottom-transition" max-width="49.7rem">
            <keep-alive>
            <component 
                :is="cargueArchivo.component"
                @close-estado="componenteEstado = false"
                @mostrar-validacion="mostrarValidacionEstado">
            </component>
            </keep-alive>
        </v-dialog>

        <!-- Dialogo para ver la observación de una relación -->
        <v-dialog v-model="dialogoObservacion" max-width="22.7rem" scrollable persistent>
            <v-card>
                <v-card-title class="fondoDialog pt-2 pb-2 pe-1" >
                    <span class="text-h6">Observación</span>
                    <v-spacer></v-spacer>
                    <v-icon class="d-flex justify-end ma-1" @click="dialogoObservacion = false" color="white">close</v-icon>
                </v-card-title>
                <v-card-text class="pt-4 pb-4">
                    <span style="color: black">{{ mensajeObservacion }}</span>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Mensaje de alerta para cuando el cambio de estado fue exitoso -->
        <template>
            <div v-if="validateProgress" class="overlay">
                <v-alert class="notificationValidationEstado" persistent transition="dialog-bottom-transition">
                    <v-icon class="d-flex justify-center" color="white" large>task_alt</v-icon>
                    <span class="d-flex justify-center">{{ mensajeExitoso }}</span>
                </v-alert>
            </div>
        </template>
    </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, } from 'vee-validate';
import { mapMutations, mapState } from "vuex";
import { Role } from "@/router/role.js";
import * as rules from 'vee-validate/dist/rules';
import { required } from 'vee-validate/dist/rules';
import RelateProductTable from "@/components/RelateProductTabla.vue"
import RelateProductSelect from '@/components/RelateProductSelect.vue';
import EstadoProductoBodega from '../../../../components/EstadoProductoBodega.vue';
import Papa from 'papaparse';

Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});

extend('required', {
    ...required,
    message: 'Este campo es requerido',
});

/**
 * Esta función toma una matriz de matrices (una matriz bidimensional) 
 * donde cada sub-matriz (o fila) representa una fila de datos en el CSV.
 * Cada elemento dentro de las sub-matrices se une con un delimitador 
 * (en este caso un punto y coma ';') y cada fila se une con un salto de línea.
 * 
 * @param {Array} data - Matriz bidimensional que contiene los datos.
 * @returns {string} - Cadena representando los datos en formato CSV.
 */
 function arrayToCSV(data) {
  return data.map(row => row.join(";")).join("\n");
}

/**
 * Esta función crea un archivo en formato CSV a partir de una cadena 
 * y luego inicia una descarga en el navegador del usuario para ese archivo.
 * 
 * @param {string} csvData - Cadena que contiene los datos en formato CSV.
 * @param {string} filename - Nombre que se asignará al archivo descargado.
 */
function downloadCSV(csvData, filename) {
  const blob = new Blob([csvData], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const debounce = function debounce(fn, delay) {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
};

export default {

    name: "RelateProduct",
    components: {
        ValidationObserver,
        RelateProductTable,
        RelateProductSelect,
        EstadoProductoBodega,
    },

    data() {
        return {
            dialog: false,
            opciones: 0,
            tabla: false,
            verCSV: true,
            registros: [],
            validaciones: [],
            validandoDocumento: false,
            formatoIncorrecto: false,
            archivoSubido: false,
            filasSeleccionadas: [],
            mostrarBotones: false,
            userRoles: {},
            roles: Role,
            lastmodifiedby: '',
            buscadorCodigoBodega: '',
            buscadorCodigoProducto: '',
            buscadorNombreProducto: '',
            buscadorNombreBodega: '',
            actEsta: 'A',
            itemSeleccionado: {},
            productoSeleccionado: false,
            codBodeSeleccionado: null,
            bodegaSeleccionada: null,
            datosExistentesError: '',
            validacionExitosa: false,
            datosErrorRelacion: '',
            validacionHdPrbod: false,
            noHayResultadosBusqueda: false,
            errorEmitidoDeLaTabla: true,
            errorWaDeLaTabla: true,
            existenciaDeRegistros: false,
            busquedaCambiada: false,
            campoBodegaVacio: false,
            headersRelaciones: [
                { text: "CÓDIGO DE BODEGA", align: "center", width: "9.2%", sortable: false, },
                { text: "CÓDIGO DEL PRODUCTO", align: "center", width: "9.8%", sortable: false, },
                { text: "NOMBRE DEL PRODUCTO", align: "left", width: "30.5%", sortable: false, },
                { text: "NOMBRE DE LA BODEGA", align: "left", width: "30%", sortable: false, },
                { text: "ESTADO", width: "11%", sortable: false, },
                { text: "ACCIONES", align: "center", width: "12%", sortable: false, }
            ],
            listaDeRelaciones: [],
            pagina: 1,
            totalPaginas: 0,
            itemsPorPagina: 10,
            loading: false,
            footerPropsTabla: {
                'items-per-page-options': [10, 15, 20, 50],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            },
            dialogoCambiarEstado: false,
            relacionSelecionada: {},
            mensajeEstado: '',
            cantidadRegistros: null,
            inProgress: false,
            buscarEstado: '',
            tiposEstado: [
                { text: 'Activo', value: 'A' },
                { text: 'Inactivo', value: 'B' },
            ],
            cargueArchivo: {
                component: 'EstadoProductoBodega',
            },
            componenteEstado: false,
            noHayFiltrosActivos: false,
            validateProgress: false,
            cantidadRegistrosEstado: null,
            mensajeIncorrecto: '',
            leyendoArchivo: false,
            registrosTemporales: [],
            observacionEstado: null,
            maximoCaracteres: 100,
            caracteresDobles: ["𝕏"],
            dialogoObservacion: false,
            mensajeObservacion: null,
        }
    },

    // Se inicializa la variable userRoles con los roles del usuario autenticado
    created() {
        this.userRoles = this.auth.roles;
    },

    // Se mapean los estados del store a propiedades computadas
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
        // Desactivar el botón de guardar cuando la búsqueda cambie y itemSeleccionado sea null
        puedeGuardarBusquedaCambiada() {
            return (this.busquedaCambiada && this.itemSeleccionado === null);
        },
        //Desactiva el boton de guardar si no se cumplen las condiciones.
        puedeGuardar() {
            return (
                !this.puedeGuardarBusquedaCambiada &&
                !this.validacionHdPrbod &&
                this.datosExistentesError === '' &&
                this.errorEmitidoDeLaTabla &&
                this.errorWaDeLaTabla &&
                this.productoSeleccionado &&
                this.bodegaSeleccionada !== null
            );
        },
        /**
        * Devuelve la cantidad de registros que tienen mensajes de validación.
        * @returns {number} - La cantidad de registros con mensajes de validación.
        */
        registrosConError() {
            const registrosConError = this.validaciones.filter(validacion => validacion.length > 0);
            return registrosConError.length;
        },
        /**
        * Filtra y devuelve los registros que tienen mensajes de validación.
        * Si no hay registros con mensajes de validación, devuelve todos los registros.
        * @returns {Array} - Los registros que tienen mensajes de validación o todos los registros si no hay errores.
        */
        registrosFiltrados() {
            if (this.registrosConError > 0) {
                const registrosFiltrados = this.registros.filter((registro, index) => this.validaciones[index].length > 0);
                return registrosFiltrados;
            }
            return this.registros;
        },
        /**
        * Filtra y devuelve las validaciones que tienen mensajes de error.
        * Si no hay registros con mensajes de validación, devuelve todas las validaciones.
        * @returns {Array} - Las validaciones que tienen mensajes de error o todas las validaciones si no hay errores.
        */
        validacionesFiltradas() {
            if (this.registrosConError > 0) {
                return this.validaciones.filter((validacion, index) => this.validaciones[index].length > 0);
            }
            return this.validaciones;
        },
        /**
        * Verifica si existen errores en las validaciones.
        * @returns {boolean} - true si hay al menos un registro con mensajes de validación, false en caso contrario.
        */
        hayErrores() {
            return this.validaciones.some(validacion => validacion.length > 0);
        },
        /** Cuenta los registros con errores */
        cantidadRegistrosConError() {
            return this.registros.filter((registro, index) => this.validaciones[index].length > 0).length;
        },
        /** Establece un valor en un mensaje para detallar la cantidad de registros procesados y si tienen error */
        mensajeCantidad() {
            const validoText = this.cantidadRegistros === 1 ? 'Se validó' : 'Se validaron';
            const registrosText = this.cantidadRegistros === 1 ? 'registro' : 'registros';
            let mensaje = `${validoText} ${this.cantidadRegistros} ${registrosText}`;
            if (this.cantidadRegistrosConError > 0) {
                const erroresText = this.cantidadRegistrosConError === 1 ? 'presenta el siguiente error' : 'presentan los siguientes errores';
                mensaje += ` y ${this.cantidadRegistrosConError} ${erroresText}.`;
            } else {
                mensaje += '.';
            }
            return mensaje;
        },
        /** Retorna true cuando se cumplen las condiciones y permite ver el icono de descarga de los resultados de busqueda */
        descargarActivo() {
            const bcp = this.buscadorCodigoProducto === null ? '' : this.buscadorCodigoProducto;
            const bcb = this.buscadorCodigoBodega === null ? '' : this.buscadorCodigoBodega;
            const be = this.buscarEstado === null ? '' : this.buscarEstado;
            const tp = (this.totalPaginas === null || this.totalPaginas === 1) ? 0 : this.totalPaginas;
            return ((bcp !== '' || bcb !== '' || be !== '') && tp > 0 && this.loading === false);
        },
        /** Retorna un mensaje con la cantidad de relaciones a las cuales se les cambio el estado */
        mensajeExitoso() {
            const mensaje = `Se actualizo el estado a ${this.cantidadRegistrosEstado} registros.`
            return mensaje;
        },
        // Contador de caracteres para el campo observacionRechazo
        contadorCaracteres() {
            let contador = 0;
            const texto = this.observacionEstado;

            if (texto) {
                for (let i = 0; i < texto.length; i++) {
                const char = texto[i];

                    if (this.caracteresDobles.includes(char)) {
                        contador += 2;
                    } else {
                        contador += 1;
                    }
                }
            }
            return contador;
        },
    },

    // Se vigila el cambio en las variables y se ejecuta listarRelaciones()
    watch: {

        pagina: function () {
            this.listarRelaciones();
        },
        itemsPorPagina: function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'buscadorCodigoBodega': debounce(function () {
            this.pagina = 1;
            this.listarRelaciones();
        }, 300),
        'buscadorCodigoProducto': debounce(function () {
            this.pagina = 1;
            this.listarRelaciones();
        }, 300),
        'buscadorNombreProducto': debounce(function () {
            this.pagina = 1;
            this.listarRelaciones();
        }, 300),
        'buscadorNombreBodega': debounce(function () {
            this.pagina = 1;
            this.listarRelaciones();
        }, 300),
        'buscarEstado': function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'validateProgress': function(newValue) {
            if (newValue === true) {
                setTimeout(() => {
                    this.validateProgress = false;
                    this.listarRelaciones();
                }, 3500);
            }
        },
        'dialogoObservacion': function(newValue) {
            if (newValue === false) {
                setTimeout(() => {
                    this.mensajeObservacion = null;
                }, 300)
            }
        },
    },

    // Se asigna el valor del username del usuario autenticado a lastmodifiedby en mayúsculas
    mounted() {
        this.lastmodifiedby = this.auth.username.toUpperCase();
    },

    methods: {
        // Se mapean las mutaciones del store a métodos locales
        ...mapMutations(["updateAuth", "hideNotify", "showSuccess", "showBusy", "hideBusy",]),

        /**
        * Método para leer la información de un archivo CSV, realizar validaciones en el backend
        * y mostrar los resultados en una tabla.
        * Este método se encarga de leer el contenido de un archivo CSV seleccionado mediante un
        * input de tipo archivo. Luego, procesa los datos y realiza una serie de validaciones en el
        * backend usando Promises. Finalmente, muestra los registros válidos en una tabla en la interfaz.
        * @param {Event} event - El evento del input de tipo archivo que contiene el archivo CSV.
        */
        lectorCSV(event) {
            this.leyendoArchivo = true;
            this.formatoIncorrecto = false;
            this.mensajeIncorrecto = '';
            const file = event.target.files[0];
            // Verificar si el archivo tiene la extensión .csv
            if (!file || file.name.split('.').pop() !== 'csv') {
                this.formatoIncorrecto = true;
                this.mensajeIncorrecto = 'Formato incorrecto, debe subir o arrastrar un archivo .csv.';
                this.leyendoArchivo = false;
                return;
            }
            
            this.validandoDocumento = true;
            this.registros = [];
            this.registrosTemporales = [];

            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                const parsedData = Papa.parse(content, {
                    header: false,
                    delimiter: ';',
                });
                if (!parsedData.data || parsedData.data.length === 0 || (parsedData.data.length === 1 && parsedData.data[0].length === 1 && parsedData.data[0][0] === '')) {
                    this.formatoIncorrecto = true;
                    this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
                    this.validandoDocumento = false;
                    this.leyendoArchivo = false;
                    return;
                }
                const registros = [];
                const headerRow = parsedData.data[0]; // Primera fila del archivo.
                const isHeaderRow = /^\d+$/.test(headerRow[0]) && /^\d+$/.test(headerRow[1]);
                // Inicio en 1 si es una fila de encabezados, de lo contrario empiezo en 0.
                for (let index = isHeaderRow ? 0 : 1; index < parsedData.data.length - 1; index++) {
                    const row = parsedData.data[index];
                    if (row[0] !== '' || row[1] !== '') {
                        registros.push({
                            producto: row[0],
                            bodega: row[1],
                        });
                    }
                }
                if (registros.length === 0) {
                    this.formatoIncorrecto = true;
                    this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
                    this.validandoDocumento = false;
                    this.leyendoArchivo = false;
                    return;
                }
                this.cantidadRegistros = registros.length;
                this.registros = registros;
                this.registrosTemporales.push([...registros]);
                this.archivoSubido = true;
                this.validaciones = Array.from({ length: this.registros.length }, () => []);
                this.registros = [];
                const backendCalls = [
                    this.validarArchivoParaWA(registros),
                    // this.validarRelacionesEnBackendInPrbod(registros),
                    // this.validarRelacionesEnBackendHdPrbod(registros),
                    // this.validarProductoEnBackendInMaite(registros),
                    // this.validarBodegaEnBackendInBodeg(registros),
                    // this.validarProductoEnBackendInMaadi(registros),
                    // this.validarRegistroEnBackendInRbous(registros),
                ];
                Promise.all(backendCalls)
                    .then(() => {
                        this.$nextTick(() => {
                            this.registros = this.registrosTemporales.flat();
                            this.registrosTemporales = [];
                            this.verCSV = false;
                            this.tabla = true;
                            this.mostrarBotones = true;
                            this.validandoDocumento = false;
                            this.leyendoArchivo = false;
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                        this.validandoDocumento = false;
                        this.mostrarBotones = false;
                        this.leyendoArchivo = false;
                    });
            };
            reader.readAsText(file);
        },

        /**
         * Valida los registros en el archivo tomando los productos y las bodegas y su relación, 
         * para comprobar si cumplen las condiciones y poder guardar la relación producto-bodega en herincohan.
         * @param {Object[]} registros - La lista de registros del archivo para validar en el back-end.
         * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
         */
        validarArchivoParaWA(registros) {
            const registrosNumericos = [];
            const mensajesErrores = {};

            registros.forEach((registro, index) => {
                if (/^\d+$/.test(registro.producto) && /^\d+$/.test(registro.bodega)) {
                    const codBode = parseInt(registro.bodega, 10);
                    if (codBode >= -32768 && codBode <= 32767) {
                        registrosNumericos.push({ codBode: registro.bodega, codProd: registro.producto, codEmpr: this.enterprise.code, obsProd: '' });
                    } else {
                        registrosNumericos.push({ codBode: '', codProd: '', codEmpr: this.enterprise.code, obsProd: '' });
                        mensajesErrores[index] = "La bodega no existe en Stone";
                        this.validaciones[index].push(mensajesErrores[index]);
                    }
                } else {
                    registrosNumericos.push({ codBode: '', codProd: '', codEmpr: this.enterprise.code, obsProd: '' }); // Marca como vacíos los registros no numéricos.

                    // Verificar si los campos están vacíos o nulos
                    if (registro.producto === null || registro.producto === "") {
                        mensajesErrores[index] = "El campo para el código de producto no puede estar vacío";
                    } else if (registro.bodega === null || registro.bodega === "") {
                        mensajesErrores[index] = "El campo para el código de bodega no puede estar vacío";
                    } else {
                        mensajesErrores[index] = "Los campos solo permiten caracteres numéricos";
                    }

                    this.validaciones[index].push(mensajesErrores[index]);
                }
            });
            return this.$http
                .post("/msa-administration/hdPrbod/validarArchivo", registrosNumericos)
                .then((response) => {
                    this.filasSeleccionadas = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        response.data.forEach((mensaje, index) => {
                            if (mensaje.trim() !== '') {
                                if (!Array.isArray(this.validaciones[index])) {
                                    this.validaciones[index] = [];
                                }
                                this.validaciones[index].push(mensaje);
                            }
                        });
                    }
                    this.verCSV = true;
                    this.validandoDocumento = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.validandoDocumento = true;
                    return Promise.reject(error);
                });
        },

        /**
        * Valida las relaciones entre códigos de bodega y códigos de producto en el backend (inPrbod).
        * @param {Object[]} registros - La lista de registros válidos a validar en el backend.
        * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
        */
        validarRelacionesEnBackendInPrbod(registros) {
            const registrosNumericos = [];
            const mensajesErrores = {};

            registros.forEach((registro, index) => {
                if (/^\d+$/.test(registro.producto) && /^\d+$/.test(registro.bodega)) {
                    const codBode = parseInt(registro.bodega, 10);
                    if (codBode >= -32768 && codBode <= 32767) {
                        registrosNumericos.push({ codBode: registro.bodega, codProd: registro.producto, codEmpr: this.enterprise.code });
                    } else {
                        registrosNumericos.push({ codBode: '', codProd: '', codEmpr: this.enterprise.code });
                        mensajesErrores[index] = "La bodega no existe en Stone";
                        this.validaciones[index].push(mensajesErrores[index]);
                    }
                } else {
                    registrosNumericos.push({ codBode: '', codProd: '', codEmpr: this.enterprise.code }); // Marca como vacíos los registros no numéricos.

                    // Verificar si los campos están vacíos o nulos
                    if (registro.producto === null || registro.producto === "") {
                        mensajesErrores[index] = "El campo para el código de producto no puede estar vacío";
                    } else if (registro.bodega === null || registro.bodega === "") {
                        mensajesErrores[index] = "El campo para el código de bodega no puede estar vacío";
                    } else {
                        mensajesErrores[index] = "Los campos solo permiten caracteres numéricos";
                    }

                    this.validaciones[index].push(mensajesErrores[index]);
                }
            });
            return this.$http
                .post("/msa-administration/inPrbod/validarRelaciones", registrosNumericos)
                .then((response) => {
                    this.filasSeleccionadas = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        response.data.forEach((mensaje, index) => {
                            if (mensaje.trim() !== '') {
                                if (!Array.isArray(this.validaciones[index])) {
                                    this.validaciones[index] = [];
                                }
                                this.validaciones[index].push(mensaje);
                            }
                        });
                    }
                    this.verCSV = true;
                    this.validandoDocumento = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.validandoDocumento = true;
                    return Promise.reject(error);
                });
        },

        /**
        * Valida las relaciones entre códigos de bodega y códigos de producto en el backend (hdPrbod).
        * @param {Object[]} registros - La lista de registros válidos a validar en el backend.
        * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
        */
        validarRelacionesEnBackendHdPrbod(registros) {
            const registrosNumericos = [];

            registros.forEach((registro) => {
                if (/^\d+$/.test(registro.producto) && /^\d+$/.test(registro.bodega)) {
                    const codBode = parseInt(registro.bodega, 10);
                    if (codBode >= -32768 && codBode <= 32767) {
                        registrosNumericos.push({ codBode: registro.bodega, codProd: registro.producto, codEmpr: this.enterprise.code });
                    } else {
                        registrosNumericos.push({ codBode: '', codProd: '', codEmpr: this.enterprise.code }); // Marca como vacíos los registros no numéricos.
                    }
                }
            });
            return this.$http
                .post("/msa-administration/hdPrbod/validarRelaciones", registrosNumericos)
                .then((response) => {
                    this.filasSeleccionadas = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        response.data.forEach((mensaje, index) => {
                            if (mensaje.trim() !== '') {
                                if (!Array.isArray(this.validaciones[index])) {
                                    this.validaciones[index] = [];
                                }
                                this.validaciones[index].push(mensaje);
                            }
                        });
                    }
                    this.verCSV = true;
                    this.validandoDocumento = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.validandoDocumento = true;
                    return Promise.reject(error);
                });
        },

        /**
        * Valida el estado del producto en el backend (inMaite).
        * @param {Object[]} registros - La lista de registros válidos a validar en el backend.
        * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
        */
        validarProductoEnBackendInMaite(registros) {
            const registrosNumericos = [];

            registros.forEach((registro) => {
                if (/^\d+$/.test(registro.producto) && /^\d+$/.test(registro.bodega)) {
                    registrosNumericos.push({ codBode: null, codProd: registro.producto, codEmpr: this.enterprise.code });
                } else {
                    registrosNumericos.push({ codBode: null, codProd: '', codEmpr: this.enterprise.code }); // Marca como nulo los registros no numéricos.
                }
            });
            return this.$http
                .post("/msa-administration/inMaite/validarEstadoProducto", registrosNumericos)
                .then((response) => {
                    this.filasSeleccionadas = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        response.data.forEach((mensaje, index) => {
                            if (mensaje.trim() !== '') {
                                if (!Array.isArray(this.validaciones[index])) {
                                    this.validaciones[index] = [];
                                }
                                this.validaciones[index].push(mensaje);
                            }
                        });
                    }
                    this.verCSV = true;
                    this.validandoDocumento = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.validandoDocumento = true;
                    return Promise.reject(error);
                });
        },

        /**
        * Valida la existencia y el nombre de la bodega en el backend (inBodeg).
        * @param {Object[]} registros - La lista de registros válidos a validar en el backend.
        * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
        */
        validarBodegaEnBackendInBodeg(registros) {
            const registrosNumericos = [];

            registros.forEach((registro) => {
                if (/^\d+$/.test(registro.producto) && /^\d+$/.test(registro.bodega)) {
                    const codBode = parseInt(registro.bodega, 10);
                    if (codBode >= -32768 && codBode <= 32767) {
                        registrosNumericos.push({ codBode: registro.bodega, codProd: null, codEmpr: this.enterprise.code });
                    } else {
                        registrosNumericos.push({ codBode: '', codProd: null, codEmpr: this.enterprise.code }); // Marca como vacíos los registros no numéricos.
                    }
                }
            });
            return this.$http
                .post("/msa-administration/inBodeg/validarBodega", registrosNumericos)
                .then((response) => {
                    this.filasSeleccionadas = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        response.data.forEach((mensaje, index) => {
                            if (mensaje.trim() !== '') {
                                if (!Array.isArray(this.validaciones[index])) {
                                    this.validaciones[index] = [];
                                }
                                this.validaciones[index].push(mensaje);
                            }
                        });
                    }
                    this.verCSV = true;
                    this.validandoDocumento = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.validandoDocumento = true;
                    return Promise.reject(error);
                });
        },

        /**
        * Valida la autorización del producto en el backend (inMaadi) cuando la columna waSolution es "s".
        * @param {Object[]} registros - La lista de registros válidos a validar en el backend.
        * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
        */
        validarProductoEnBackendInMaadi(registros) {
            const registrosNumericos = [];

            registros.forEach((registro) => {
                if (/^\d+$/.test(registro.producto) && /^\d+$/.test(registro.bodega)) {
                    registrosNumericos.push({ codBode: null, codProd: registro.producto, codEmpr: this.enterprise.code });
                } else {
                    registrosNumericos.push({ codBode: null, codProd: '', codEmpr: this.enterprise.code }); // Marca como nulo los registros no numéricos.
                }
            });
            return this.$http
                .post("/msa-administration/inMaadi/validarAutorizacionProducto", registrosNumericos)
                .then((response) => {
                    this.filasSeleccionadas = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        response.data.forEach((mensaje, index) => {
                            if (mensaje.trim() !== '') {
                                if (!Array.isArray(this.validaciones[index])) {
                                    this.validaciones[index] = [];
                                }
                                this.validaciones[index].push(mensaje);
                            }
                        });
                    }
                    this.verCSV = true;
                    this.validandoDocumento = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.validandoDocumento = true;
                    return Promise.reject(error);
                });
        },

        /**
        * Valida la existencia de un registro que contenga el código de bodega enviado.
        * @param {Object[]} registros - La lista de registros válidos a validar en el backend.
        * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
        */
        validarRegistroEnBackendInRbous(registros) {
            const registrosNumericos = [];

            registros.forEach((registro) => {
                if (/^\d+$/.test(registro.producto) && /^\d+$/.test(registro.bodega)) {
                    const codBode = parseInt(registro.bodega, 10);
                    if (codBode >= -32768 && codBode <= 32767) {
                        registrosNumericos.push({ codBode: registro.bodega, codProd: null, codEmpr: this.enterprise.code });
                    } else {
                        registrosNumericos.push({ codBode: '', codProd: null, codEmpr: this.enterprise.code }); // Marca como vacíos los registros no numéricos.
                    }
                }
            });
            return this.$http
                .post("/msa-administration/inRbous/validarRequisito", registrosNumericos)
                .then((response) => {
                    this.filasSeleccionadas = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        response.data.forEach((mensaje, index) => {
                            if (mensaje.trim() !== '') {
                                if (!Array.isArray(this.validaciones[index])) {
                                    this.validaciones[index] = [];
                                }
                                this.validaciones[index].push(mensaje);
                            }
                        });
                    }
                    this.verCSV = true;
                    this.validandoDocumento = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.validandoDocumento = true;
                    return Promise.reject(error);
                });
        },

        /**
        * Reinicia el estado del componente para volver a cargar un nuevo documento CSV.
        * Este método se encarga de reiniciar todas las variables de estado relacionadas con la
        * carga de documentos y las validaciones de manera que se pueda seleccionar y cargar
        * un nuevo archivo CSV para ser procesado nuevamente.
        */
        volverACargarDocumento() {
            this.validaciones = [];
            this.registros = [];
            this.archivoSubido = false;
            this.filasSeleccionadas = [];
            this.validandoDocumento = false;
            this.formatoIncorrecto = false;
            this.verCSV = true;
            this.mostrarBotones = false;
            this.tabla = false;
            this.$refs.cargaDeRelaciones.value = '';
        },

        /**
        * Guarda las relaciones del archivo en forma masiva en la base de datos.
        * Este método toma los registros válidos del archivo previamente cargado y filtrados
        * para asegurarse de que no haya registros duplicados. Luego, realiza una petición al
        * backend para guardar las relaciones de productos en la base de datos.
        */
        guardarMultiplesRelaciones() {
            const horaActual = new Date();
            const registrosUnicos = this.registros.filter((registro, index, self) =>
                index === self.findIndex((r) => r.producto === registro.producto && r.bodega === registro.bodega)
            );
            if (registrosUnicos.length === 0) {
                return;
            }
            const relacionarProductos = registrosUnicos.map((registro) => {
                return {
                    idPrbo: null,
                    idEmpresa: this.enterprise.code,
                    codigoProducto: Number(registro.producto),
                    bodegasId: {
                        idEmpresa: this.enterprise.code,
                        codigoBodega: Number(registro.bodega),
                    },
                    eliminado: false,
                    estado: this.actEsta,
                    usuario: this.lastmodifiedby,
                    fecha: horaActual,
                    observacion: '',
                };
            });
            this.inProgress = true;
            this.$http
                .post(`msa-administration/hdPrbod/crearRelacion`, relacionarProductos)
                .then(() => {
                    this.listarRelaciones();
                    this.reset();
                    setTimeout(() => {
                        this.inProgress = false;
                    }, 500);
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
        * Función para validar la entrada de caracteres en un evento de tecla.
        * Se encarga de validar que solo se ingresen ciertos caracteres permitidos.
        * @param {Event} event - Evento de tecla
        */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.toLowerCase() !== "e" && teclaPresionada !== "-" && teclaPresionada !== "+" && teclaPresionada !== ",") {
                const regex = /[0-9$]/g;
                if (!regex.test(teclaPresionada)) {
                    event.preventDefault();
                }
            } else {
                event.preventDefault();
            }
        },

        /**
        * Función para validar el campo de seleción de bodega en el componente RelateProductSelect.
        * Se encarga de validar el campo vacio.
        */
        campoVacio(){
            this.campoBodegaVacio = true;
        },
        
        /**
        * Manejo de la selección de una bodega.
        * Se encarga de manejar la lógica cuando se selecciona una bodega.
        * @param {string|null} bodegaId - ID de la bodega seleccionada
        */
        manejoDeLaBodega(bodegaId) {
            this.codBodeSeleccionado = bodegaId;
            this.bodegaSeleccionada = bodegaId;
            this.campoBodegaVacio = false;
            this.validarExistencia();
            this.validarExistenciaEnHdPrbod();

            // Deshabilitar el botón de guardar
            if (bodegaId === null || this.itemSeleccionado === null) {
                this.existenciaDeRegistros = false;
                // Habilitar el botón de guardar
            } else {
                this.existenciaDeRegistros = true;
            }
        },

        /**
        * Manejo de la busqueda en los filtros de la tabla.
        * Se encarga de manejar la lógica cuando se cambia la busqueda en los filtros.
        * @param {Object} valor 
        */
        manejoBusquedaCambiada(valor) {
            this.busquedaCambiada = valor;
            this.itemSeleccionado = null;
            this.datosExistentesError = '';
            this.validacionExitosa = false;
        },

        /**
        * Manejo de la selección de un item.
        * Se encarga de manejar la lógica cuando se selecciona un item.
        * @param {Object} item - Item seleccionado
        */
        manejoDelItemSeleccionado(item) {
            if (item) {
                this.itemSeleccionado = item;
                this.validarExistencia();
                this.validarExistenciaEnHdPrbod();
                this.existenciadeProducto();
            }
        },

        /**
        * Verifica la existencia del producto seleccionado
        * Si no existe un producto seleccionado no se activa el botón de guardar
        */
        existenciadeProducto() {
            this.productoSeleccionado = Object.keys(this.itemSeleccionado).length > 0;
            this.existenciaDeRegistros = this.productoSeleccionado && this.bodegaSeleccionada;
        },

        /**
        * Activa el botón de guardar
        */
        activarBotonGuardar() {
            this.errorEmitidoDeLaTabla = true;
        },

        /**
        * Desactiva el botón de guardar
        */
        desactivarBotonGuardar() {
            this.errorEmitidoDeLaTabla = false;
        },

        /**
        * Activa el botón de guardar por falla en las condiciones del código de producto.
        */
        noGuardar() {
            this.errorWaDeLaTabla = false;
        },

        /**
        * Desactiva el botón de guardar cuando el código del producto cumple las condiciones.
        */
        siGuardar() {
            this.errorWaDeLaTabla = true;
        },

        /**
         * Se encarga de realizar una validación para verificar si existe una relación entre el código de producto
         * y el código de bodega seleccionados en la tabla IN_PRBOD de Stone.
         */
        validarExistencia() {
            if (this.itemSeleccionado !== null && this.codBodeSeleccionado !== null && this.busquedaCambiada) {
                if (this.itemSeleccionado.codProd || this.itemSeleccionado.codProd === 0) {
                    this.$http
                        .get("msa-administration/inPrbod/validarExistencia", {
                            params: {
                                codProd: this.itemSeleccionado.codProd,
                                codBode: this.codBodeSeleccionado,
                                codEmpr: this.enterprise.code,
                            }
                        })
                        .then((response) => {
                            if (response.data !== "Los datos existen") {
                                this.datosExistentesError = "La relación con el código de bodega y el código de producto no existe en Stone.";
                                this.validacionExitosa = true; // La validación no fue exitosa
                                return;
                            } else {
                                this.datosExistentesError = '';
                                this.validacionExitosa = false; // La validación fue exitosa
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.datosExistentesError = "Error en la validación de datos";
                            this.validacionExitosa = true; // La validación no fue exitosa
                        });
                } else {
                    this.datosExistentesError = '';
                    this.validacionExitosa = false; // La validación no fue exitosa
                }
            } else {
                this.datosExistentesError = '';
                this.validacionExitosa = false;
            }
        },

        /**
         * Se encarga de realizar una validación para verificar si existe una relación entre el código de producto
         * y el código de bodega seleccionados en la tabla HD_PRBOD de Stone y en la tabla producto_bodega de PostgreSQL.
         */
        validarExistenciaEnHdPrbod() {
            if (this.itemSeleccionado !== null && this.codBodeSeleccionado !== null && this.busquedaCambiada) {
                if (this.itemSeleccionado.codProd || this.itemSeleccionado.codProd === 0) {
                    this.$http.get("msa-administration/hdPrbod/existe", {
                            params: {
                                codBode: this.codBodeSeleccionado,
                                codProd: this.itemSeleccionado.codProd,
                                codEmpr: this.enterprise.code,
                            }
                        }).then((response) => {
                            if (response.data === "Los datos existen") {
                                this.datosErrorRelacion = "La relación con el código de bodega y el código de producto ya existe.";
                                this.validacionHdPrbod = true; // La validación no fue exitosa
                            } else {
                                this.$http.get("msa-administration/productoBodega/existe", {
                                    params: {
                                        codigoProducto: this.itemSeleccionado.codProd,
                                        codigoBodega: this.codBodeSeleccionado,
                                        idEmpresa: this.enterprise.code,
                                    } 
                                }).then((result) => {
                                    if (result.data === "Los datos existen") {
                                        this.datosErrorRelacion = "La relación con el código de bodega y el código de producto ya existe.";
                                        this.validacionHdPrbod = true;
                                    } else {
                                        this.datosErrorRelacion = "";
                                        this.validacionHdPrbod = false; // La validación fue exitosa
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                    this.datosErrorRelacion = "Error en la validación de datos";
                                    this.validacionHdPrbod = true; // La validación no fue exitosa
                                })
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.datosErrorRelacion = "Error en la validación de datos";
                            this.validacionHdPrbod = true; // La validación no fue exitosa
                        });
                } else {
                    this.datosErrorRelacion = "";
                    this.validacionHdPrbod = false; // La validación no fue exitosa
                }
            } else {
                this.datosErrorRelacion = "";
                this.validacionHdPrbod = false;
            }
        },

        /**
         * Guarda la relación de productos.
         * Realiza una petición al backend para guardar la relación de productos en base de datos.
         * La constante relacionarProducto contiene los atributos que reciben los datos para enviarlos al back.
         */
        guardarRelacion() {
            const horaActual = new Date;
            // Realiza la petición al backend para guardar la relación
            const relacionarProducto = {
                idPrbo: null,
                idEmpresa: this.enterprise.code,
                codigoProducto: this.itemSeleccionado.codProd,
                bodegasId: {
                    idEmpresa: this.enterprise.code,
                    codigoBodega: this.codBodeSeleccionado,
                },
                eliminado: false,
                estado: this.actEsta,
                usuario: this.lastmodifiedby,
                fecha: horaActual,
                observacion: '',
            };
            this.inProgress = true;
            const relacionarProductos = [relacionarProducto];
            // Realizar la petición POST al controlador para guardar la relación
            this.$http
                .post(`msa-administration/hdPrbod/crearRelacion`, relacionarProductos)
                .then(() => {
                    this.listarRelaciones();
                    this.reset();
                    setTimeout(() => {
                        this.inProgress = false;
                    }, 500);
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * Lista las relaciones de productos.
         * Realiza una petición al backend para obtener la lista de relaciones de productos.
         */
        async listarRelaciones() {
            this.loading = true;
            this.buscarEstado = this.buscarEstado === null ? '' : this.buscarEstado;
            this.noHayFiltrosActivos = false;
            if (this.buscadorCodigoProducto === '' && this.buscadorCodigoBodega === '' && this.buscadorNombreProducto === '' && this.buscadorNombreBodega === '' && this.buscarEstado === '') {
                this.listaDeRelaciones = [];
                this.loading = false;
                this.totalPaginas = 0;
                this.noHayResultadosBusqueda = false;
                this.noHayFiltrosActivos = true;
                return;
            }
            this.noHayFiltrosActivos = false;
            try {
                const response = await this.$http.get(`msa-administration/hdPrbod/listar`, {
                    params: {
                        page: this.pagina - 1,
                        size: this.itemsPorPagina,
                        codEmpr: this.enterprise.code,
                        codProd: `${this.buscadorCodigoProducto}`,
                        codBode: `${this.buscadorCodigoBodega}`,
                        nomProd: `${this.buscadorNombreProducto.toLowerCase()}`,
                        nomBode: `${this.buscadorNombreBodega.toLowerCase()}`,
                        actEsta: `${this.buscarEstado !== null ? this.buscarEstado : ''}`,
                    },
                });
                if (response.data.content.length === 0) {
                    this.noHayResultadosBusqueda = true;
                    this.listaDeRelaciones = [];
                    this.totalPaginas = 0;
                } else if (!this.noHayFiltrosActivos) {
                    this.noHayResultadosBusqueda = false;
                    this.listaDeRelaciones = response.data.content;
                    this.totalPaginas = response.data.totalElements;
                }
                    this.loading = false;
            } catch (error) {
                console.log(error);
                this.listaDeRelaciones = [];
                this.loading = false;
                this.totalPaginas = 0;
                this.noHayResultadosBusqueda = false;
            }
        },

        /**
         * Calcula si no hay escritura en los campos de búsqueda
         * @returns {boolean} - Indicador de no hay escritura
         */
        calcularNoHayEscritura() {
            this.buscarEstado = this.buscarEstado === null ? '' : this.buscarEstado;
            return this.buscadorCodigoProducto === '' && this.buscadorCodigoBodega === '' && this.buscadorNombreProducto === '' && this.buscadorNombreBodega === '' && this.buscarEstado === '';
        },

        /**
         * Abre el diálogo para cambiar el estado de una relación
         * @param {Object} item - Item seleccionado
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true;
            this.relacionSelecionada = item;
            this.mensajeEstado = this.relacionSelecionada.ACT_ESTA === 'A' ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
            setTimeout(() => {
                this.mensajeEstado = this.relacionSelecionada.ACT_ESTA === 'A' ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
            }, 4000);
        },

        /**
         * Cambia el estado de una relación
         */
        cambiarEstado() {
            this.relacionSelecionada.ACT_ESTA = (this.relacionSelecionada.ACT_ESTA == 'A') ? 'B' : 'A';
            let eliminado = (this.relacionSelecionada.ACT_ESTA == 'A') ? false : true;
            const horaActual = new Date;
            const relacionarProducto = {
                idPrbo: this.relacionSelecionada.RMT_PRBO,
                idEmpresa: this.enterprise.code,
                codigoProducto: this.relacionSelecionada.COD_PROD,
                bodegasId: {
                    idEmpresa: this.enterprise.code,
                    codigoBodega:this.relacionSelecionada.COD_BODE,
                },
                eliminado: eliminado,
                estado: this.relacionSelecionada.ACT_ESTA,
                usuario: this.lastmodifiedby,
                fecha: horaActual,
                observacion: this.observacionEstado === null ? '' : this.observacionEstado,
            };
            this.inProgress = true;
            this.$http
                .put(`msa-administration/hdPrbod/cambiarEstado`, relacionarProducto)
                .then(() => {
                    this.dialogoCambiarEstado = false;
                    this.listarRelaciones();
                    setTimeout(() => {
                        this.observacionEstado = null;
                        this.inProgress = false;
                    }, 500);
                }).catch(error => {
                    console.log(error);
                })
        },

        /**
         * Restablece los valores y estados de los componentes cuando se cierr el dialog para crear
         */
        reset() {
            this.dialog = false;
            this.opciones = 0;
            this.$refs.relateProductTable.buscador1 = '';
            this.$refs.relateProductTable.buscador2 = '';
            this.$refs.relateProductTable.productoSeleccionado = [];
            this.$refs.relateProductTable.pagina = 1;
            this.$refs.relateProductTable.totalPaginas = 0;
            this.itemSeleccionado = {};
            this.codBodeSeleccionado = null;
            this.$refs.relateProductTable.limpiar();
            this.$refs.relateProductSelect.restablecerTextDeBusqueda();
            this.datosExistentesError = '';
            this.datosErrorRelacion = '',
            this.dialogoCambiarEstado = false;
            this.$refs.observer.reset();
            this.validacionExitosa = false;
            this.validacionHdPrbod = false;
            this.validaciones = [];
            this.archivoSubido = false;
            this.filasSeleccionadas = [];
            this.validandoDocumento = false;
            this.formatoIncorrecto = false;
            this.verCSV = true;
            this.mostrarBotones = false;
            this.tabla = false;
            this.observacionEstado = null;
            if (this.$refs.cargaDeRelaciones) {
                this.$refs.cargaDeRelaciones.value = '';
            }
        },
        /**
         * Maneja el evento de presionar la tecla Tab para campos v-autocomplete.
         * Si se presiona la tecla Tab y el valor actual del modelo es null,
         * desenfoca el campo y establece el valor del modelo como una cadena vacía.
         *
         * @param {KeyboardEvent} event - El objeto del evento de teclado.
         * @param {string} model - El nombre de la propiedad del modelo.
         * @param {string} ref - El nombre de la referencia del campo v-autocomplete.
         */
        tabulador(event, model, ref) {
            if (event.key === 'Tab' && this[model] === null) {
                this.$refs[ref].blur();
                this[model] = '';
            }
        },
        /**
         * Muestra la validación del estado de los registros.
         * Actualiza la cantidad de registros validados y establece el progreso de validación como verdadero.
         * 
         * @param {number} cantidadRegistros - La cantidad de registros que se han validado.
         */
        mostrarValidacionEstado(cantidadRegistros) {
            this.cantidadRegistrosEstado = cantidadRegistros;
            this.validateProgress = true;
        },
        /**
         * Solicita y procesa los datos de las relaciones producto bodega basándose en los filtros.
         * La respuesta esperada es un blob que contiene datos en formato CSV. 
         * Estos datos son luego procesados y convertidos a un archivo .csv para su descarga.
         */
        async descargarResultadoDeBusqueda() {
            try {
                const response = await this.$http.get(`msa-administration/hdPrbod/csv`, {
                    responseType: 'bolb',
                    params: {
                        page: this.pagina - 1,
                        size: this.itemsPorPagina,
                        codEmpr: this.enterprise.code,
                        codProd: `${this.buscadorCodigoProducto}`,
                        codBode: `${this.buscadorCodigoBodega}`,
                        nomProd: `${this.buscadorNombreProducto.toLowerCase()}`,
                        nomBode: `${this.buscadorNombreBodega.toLowerCase()}`,
                        actEsta: `${this.buscarEstado !== null ? this.buscarEstado : ''}`,
                    },
                });
                let estado = this.buscarEstado === 'A' ? '_activas' : this.buscarEstado === 'B' ? '_inactivas' : '';
                let bodega = this.buscadorCodigoBodega !== '' ? this.buscadorCodigoBodega : '';
                let producto = this.buscadorCodigoProducto !== '' ? this.buscadorCodigoProducto : '';
                let texto = bodega !== '' ? `_bodega_${bodega}` : producto !== '' ? `_producto_${producto}` : '';
                const filename = `Relacion_producto_bodega${estado}${texto}.csv`;
                Papa.parse(response.data, {
                    complete: (results) => {
                        const csvData = arrayToCSV(results.data);
                        if (results.data.length > 2) {
                            downloadCSV(csvData, filename);
                        } 
                    }
                })
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Descarga el resultado de las validaciones como un archivo CSV.
         */
        descargarResultadoDeValidaciones() {
            const csvContent = this.generarCSV();
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'validaciones_producto_bodega.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        /**
         * Genera el contenido del archivo CSV a partir de los registros y sus validaciones.
         * @returns {string} - El contenido del archivo CSV.
         */
        generarCSV() {
            const eliminarTildes = (texto) => {
                return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            };
            const limpiarMensaje = (mensaje) => {
                return mensaje.replace(/\n/g, ', ');
            };

            let csv = 'Producto;Bodega;Validacion\n';
            // Descarga todos los registros, con error o sin error en las validaciones.
            // this.registros.forEach((registro, index) => {
            //     const producto = registro.producto ? registro.producto : '';
            //     const bodega = registro.bodega ? registro.bodega : '';
            //     const validacion = this.validaciones[index] && this.validaciones[index].length > 0 
            //         ? this.validaciones[index].map(mensaje => limpiarMensaje(eliminarTildes(mensaje))).join(', ')
            //         : '';

            //     csv += `${producto};${bodega};${validacion}\n`;
            // });

            // Descarga solo los registros con error en las validaciones 
            this.registros.forEach((registro, index) => {
                if (this.validaciones[index] && this.validaciones[index].length > 0) {
                    const producto = registro.producto ? registro.producto : '';
                    const bodega = registro.bodega ? registro.bodega : '';
                    const validaciones = this.validaciones[index]
                        .map(mensaje => limpiarMensaje(eliminarTildes(mensaje)))
                        .join(', ');

                    csv += `${producto};${bodega};${validaciones}\n`;
                }
            });
            return csv;
        },
        /**
         * Abre un dialogo para ver la observación de la relación seleccionada.
         * @param item - Relación seleccionada.
         */
        abrirDialogoObservacion(item) {
            this.mensajeObservacion = item.OBS_PROD;
            this.dialogoObservacion = true;
        },
    },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
::v-deep .elevation .v-data-footer {
    width: 100%;
}
::v-deep .elevation .v-data-footer__select .v-select {
    margin: 5px;
    margin-left: 10px;
}
::v-deep .elevation .v-data-table__wrapper {
    border: 1px solid #f7f6f6;
}
.fondoDialog {
    background-color: #0d47a1;
    color: rgb(223, 223, 223) !important;
}
.mensaje {
    text-align: center;
    padding-top: 20vh !important;
}
.mensaje em {
    font-size: larger;
    color: rgb(161 161 162);
}
.mensaje:hover {
    background-color: white;
}
.icono {
    font-size: 50px;
}
::v-deep .v-tabs .v-tabs-slider-wrapper {
    top: 80% !important;
}
::v-deep .tablaRelaciones div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}
.header tr {
    background-color: rgb(223, 223, 223) !important;
}
.header tr th {
    font-weight: bold;
    color: black !important;
}
.archivo {
    outline: 2px dashed grey;
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 1rem;
    height: 100px;
    position: relative;
    cursor: pointer;
}
.archivo p {
    margin-top: .7rem;
    text-align: center;
}
.seleccionarArchivo {
    opacity: 0;
    width: 100%;
    height: 100px;
    position: absolute;
    cursor: pointer;
}
.seleccionarArchivo:disabled {
  cursor: default;
}
.error-message {
    color: red;
    white-space: pre-line;
}
.rotate-animation {
    animation: rotate 2s linear infinite;
    cursor: default;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.notificationValidation {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 15vw;
}
.notificationValidationEstado {
position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 28vw;
}
.deshabilitar {
    pointer-events: none;
    cursor: default;
}
::v-deep #observacionId {
  margin: 5px;
}
</style>